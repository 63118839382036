import React from "react";
import "./ManagementComp.css";
import MousumiMajumdar from "../../resource/Management_team/Mousumi_mam.jpg";
import IndiraRoy from "../../resource/Management_team/Indira_mam.png";
import RumaDeb from "../../resource/Management_team/Ruma_Mam.jpg";
import ShaktiNathHalder from "../../resource/Management_team/Sakti_sir.png";
import SouravRoy from "../../resource/Management_team/sourav_sir.png";
import ParikshitDas from "../../resource/Management_team/Parikshit_sir.png";
import Clement from "../../resource/Management_team/Clement_sir.jpg";
import Prathyusha from "../../resource/Management_team/Pratyusha.jpg";
import Souvik from "../../resource/Management_team/Souvik.jpg";
import Deep from "../../resource/Management_team/DEEP.jpg";
import Tarannam from "../../resource/Management_team/Tarannum.jpg";
import { InView } from "react-intersection-observer";

const ManagementComp = () => {
  return (
    <>
      <section className="boardmembers">
        <div className="container">
          {/* Parikshit Sir */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Parikshit Das</h1>
                  <h2>Hr Manager</h2>
                  <p>
                    A passionate HR professional, Mr Parikshit Das brings a
                    wealth of experience and knowledge to Sampurna. Holding a
                    BBA (HR) & GDBA from NDIM, New Delhi, along with a PG degree
                    (HR) from IISWBM, Kolkata, he boasts a strong academic
                    foundation. With nearly 11 years of experience across
                    various corporates, INGOs, and NGOs in several Indian
                    states, Mr Das possesses a diverse and well-rounded
                    understanding of HR operations and Learning & Development.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img src={ParikshitDas} alt="pic" />
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* Mousumi Mam */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Mousumi Majumdar</h1>
                  <h2>Finance Manager</h2>
                  <p>
                    Mousumi Majumdar is a graduate from a reputed college in
                    Kolkata. She holds an MBA degree in finance. Currently she
                    oversees the finance and accounts. She has attended various
                    workshops and conferences that has helped Sampurna upgrade
                    to a better working ambience. She has been associated with
                    the organization since 2012.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img src={MousumiMajumdar} alt="pic" />
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* Sourav Sir */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row   transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Sourav Roy</h1>
                  <h2>IT Head</h2>
                  <p>
                    He has joined Sampurna in 2021. Right now he is the IT head
                    of our company and helms the cap of our structural backbone
                    IT department. His focus has been on enhancing the IT
                    infrastructure, improving cybersecurity, and implementing
                    cutting-edge solutions to support our business goals. He has
                    been looking forward to collaborating to create a robust and
                    agile IT environment that will propel our company towards
                    continued success.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img src={SouravRoy} alt="pic" />
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* Shakti Sir */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Shakti Nath Halder</h1>
                  <h2>Head - Internal Audit</h2>
                  <p>
                    Sakti Nath Halder has more tha 10 years of experience in the
                    field of microfinance. He was working as Internal Auditor
                    from 2013. Now he is working as a Head of the Internal Audit
                    department.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img src={ShaktiNathHalder} alt="pic" />
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* { Indira Mam } */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Indira Roy</h1>
                  <h2>Senior L&D Manager</h2>
                  <p>
                    Indira Roy was associated with the role of Microfinance
                    Coordinator in Sampurna. She is an MBA degree holder with
                    10-years-long industry-experience. Her strong will and
                    dedication has kept motivating the employees during high and
                    low of the organization.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img src={IndiraRoy} alt="pic" />
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* Clement */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Clement Pawan Kerketta</h1>
                  <h2>Area Manager, VT</h2>
                  <p>
                    Clement Pawan Kerketta, a passionate management, and
                    development professional is a top-ranked B-school graduate.
                    He has been demonstrating a sturdy commitment to serving
                    disadvantaged youth and adults, including homeless,
                    emancipated youth, and special-needs populations in his
                    fifteen years of professional experience. He has vast
                    expertise in entrepreneurship and livelihood initiatives;
                    has extensively worked in Corporate Social Responsibility
                    projects in states like Jharkhand, Assam, and Youth training
                    and placement in West Bengal.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img
                      src={Clement}
                      alt="pic"
                      style={{ objectPosition: "bottom" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </InView>

          {/* Ruma Mam */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Ruma Deb</h1>
                  <h2>MIS Head</h2>
                  <p>
                    Ruma Deb is Bsc in IT. She has more than 10 years of
                    experience in this sector of Microfinance. She has vast
                    knowledge in dealing with software. She has been associated
                    with Sampurna as MIS coordinator since the beginning. Now
                    she is head of the MIS department.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img src={RumaDeb} alt="pic" />
                  </div>
                </div>
              </div>
            )}
          </InView>

          {/* Pratyusha di */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Pratyusha Chatterjee</h1>
                  <h2>Communication Manager</h2>
                  <p>
                    Introducing Pratyusha Chatterjee, a dynamic Communication
                    Manager with Sampurna, boasting a Master's degree in Mass
                    Communication and five years of rich industry experience.
                    With an extensive portfolio spanning diverse regions across
                    India, Pratyusha has honed her expertise in media, public
                    relations, content creation, and corporate communication.
                    Her journey reflects a commitment to excellence, navigating
                    the intricate landscapes of communication with finesse and
                    innovation. As Sampurna's Communication Manager, Pratyusha
                    embodies a fusion of strategic vision and tactical
                    execution, poised to elevate brand narratives and amplify
                    engagement on every front.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img
                      src={Prathyusha}
                      alt="pic"
                      style={{ objectPosition: "bottom" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </InView>

          {/* Deep Debnath */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Deep Debnath</h1>
                  <h2>Master Trainer (Beauty & Makeup)</h2>
                  <p>
                    Deep Debnath, a passionate makeup and beauty enthusiast, is
                    a certified master trainer with a wealth of experience in
                    the industry. His journey into the world of beauty began
                    with PMKBY, a government-sponsored beautician training
                    program. With 3 years of freelance experience, 2 years in
                    the film industry, 5 years in the fashion industry, and 1
                    year of parlour experience, Deep has a proven track record
                    of success in the beauty industry. He is passionate about
                    sharing her knowledge and skills with others, and her
                    training sessions are highly sought-after.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img
                      src={Deep}
                      alt="pic"
                      style={{ objectPosition: "bottom" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </InView>

          {/* Souvik */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Souvik Das</h1>
                  <h2>Master Trainer (Computer)</h2>
                  <p>
                    Souvik Das is a dedicated and skilled professional with a
                    solid educational background in commerce and a comprehensive
                    array of certifications in information technology and
                    financial accounting. In his role as Master Trainer of
                    Computer Education, Souvik leverages his extensive knowledge
                    and diverse skill set to lead the department with innovation
                    and excellence. His background in both commerce and IT
                    enables him to provide a holistic approach to computer
                    education, integrating practical applications with
                    theoretical knowledge. Souvik's commitment to education and
                    his ability to stay abreast of technological advancements
                    make him a vital asset to Sampurna Skills, where he is
                    dedicated to preparing students for the evolving demands of
                    the digital world.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img
                      src={Souvik}
                      alt="pic"
                      style={{ objectPosition: "bottom" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </InView>

          {/* Tarannam */}
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`board flex flex-col-reverse sm:flex-row  transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-36 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <div className="boardleft">
                  <h1>Masiha Tarannum Razi</h1>
                  <h2>Master Trainer (Dress Designing)</h2>
                  <p>
                    Masiha Tarannum Razi is a versatile and accomplished
                    professional with a strong educational foundation in
                    geography and a deep passion for fashion design. In addition
                    to her fashion credentials, She has diversified her skill
                    set with a Certificate in Information Technology Application
                    (CITA) from the Youth Computer Training Centre, equipping
                    her with valuable technological competencies.
                  </p>
                </div>
                <div className="boardright">
                  <div className="img">
                    <img
                      src={Tarannam}
                      alt="pic"
                      style={{ objectPosition: "bottom" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </>
  );
};

export default ManagementComp;
