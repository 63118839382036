import React from "react";
import "./RoadmapComp.css";
import { InView } from "react-intersection-observer";

const RoadmapComp = () => {
  return (
    <>
      <section className="roadmap">
        <InView triggerOnce={true} threshold={0.1}>
          {({ inView, ref }) => (
            <h1
              className={`sec_heading ${
                inView
                  ? "opacity-100 translate-y-0"
                  : "translate-y-36 opacity-0"
              } duration-700`}
              ref={ref}
            >
              Key Milestone
            </h1>
          )}
        </InView>
        <div className={`container`}>
          <InView triggerOnce={true} threshold={.1} >
            {({ inView, ref }) => (
              <div
                className={`line ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "translate-y-52 opacity-0"
                } duration-700`}
                ref={ref}
              >
                <div className="line_box">
                  <i className="fa-solid fa-school"></i>
                  <div className="innerline">
                    <div className="roadmap_content">
                      <ul>
                        <li>Company Formation</li>
                        <li>50+ Students</li>
                      </ul>
                    </div>
                    <span>2017</span>
                  </div>
                </div>
                <div className="line_box">
                  <i className="fa-solid fa-building-wheat"></i>
                  <div className="innerline">
                    <div className="roadmap_content">
                      <ul>
                        <li>15+ Centers</li>
                        <li>11+ Courses</li>
                      </ul>
                    </div>
                    <span>2020</span>
                  </div>
                </div>
                <div className="line_box">
                  <i className="fa-solid fa-stamp"></i>
                  <div className="innerline">
                    <div className="roadmap_content">
                      <ul>
                        <li>ISO Certified</li>
                        <li>MSME Certified</li>
                      </ul>
                    </div>
                    <span>2022</span>
                  </div>
                </div>
                <div className="line_box">
                  <i className="fa-solid fa-person-chalkboard"></i>
                  <div className="innerline">
                    <div className="roadmap_content">
                      <ul>
                        <li>10K+ Students</li>
                        <li>15+ Trainers</li>
                      </ul>
                    </div>
                    <span>2022</span>
                  </div>
                </div>
                <div className="line_box">
                  <i className="fa-solid fa-chalkboard-user"></i>
                  <div className="innerline">
                    <div className="roadmap_content">
                      <ul>
                        <li>20+ Centers</li>
                        <li>40+ Trainers</li>
                      </ul>
                    </div>
                    <span>2023</span>
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </>
  );
};

export default RoadmapComp;
