import React, { useState } from "react";
import "./ContactComp.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const ContactComp = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const sendMail = async (e) => {
    e.preventDefault();
    const res = await fetch("https://manage.sampurnaskills.co.in/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        number,
        message,
      }),
    });
    const data = await res.json();
    if (data.status === 401 || !data) {
      //   console.log("Error");
      toast.error("Email not send", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      //   console.log("Email send");
      toast.success("Email send", {
        position: toast.POSITION.TOP_CENTER,
      });
      setEmail("");
      setName("");
      setNumber("");
      setMessage("");
    }
  };

  return (
    <>
      <section className="contact animate-slideup">
        <div className="container">
          <div className="contact-left">
            <h1>Contact Us</h1>
            <p>
              Want to work with us or need more details about{" "}
              <strong>SAMPURNA</strong>.
            </p>
            <form id="contact-form" method="post">
              <div className="con-name">
                <div className="field name">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="field number">
                  <input
                    type="number"
                    name="number"
                    value={number}
                    id="number"
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Your Number"
                    required
                  />
                </div>
              </div>
              <div className="con-email">
                <input
                  type="email"
                  name="email"
                  value={email}
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="con-textarea">
                <textarea
                  name="message"
                  id="massage"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  cols="30"
                  rows="8"
                  placeholder="Describe your message"
                  required
                ></textarea>
              </div>
              <div className="button">
                <button type="submit" name="mailsend" onClick={sendMail}>
                  Send Massage
                </button>
              </div>
            </form>
          </div>

          <div className="contact-right">
            <div className="right-container">
              <div className="address">
                <i className="fa-solid fa-map-location"></i>{" "}
                <Link
                  to={"https://maps.app.goo.gl/WhEjaXoj8dB6avT38"}
                  target="_blank"
                >
                  <p>
                    3rd Floor (North), DN-30, Sector V, Salt Lake, Kolkata-700091.
                  </p>
                </Link>
              </div>
              <div className="address">
                <i className="fa-solid fa-phone"></i>
                <div className="phone_no_group">
                  <Link to={"tel:+03335081849"}>
                    <p>Grievance Redressal: (033) 3508-1849</p>
                  </Link>
                  <Link to={"tel:+916292276444"}>
                    <p>Toll Free Number: +91 62922-76444 </p>
                  </Link>
                  <Link to={"https://wa.me/+918100971969"} target="_blank">
                    <p>Whatsapp Number: +91 81009-71969 </p>
                  </Link>
                </div>
              </div>
              <div className="address">
                <i className="fa-solid fa-envelope"></i>{" "}
                <Link
                  to="https:///mail.google.com/mail/?view=cm&fs=1&to=contactus@sampurna.co.in"
                  target="_blank"
                >
                  <p>Email: contactus@sampurna.co.in</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default ContactComp;
