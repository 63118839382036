import React from "react";
import YouTube from "react-youtube";
import { InView } from "react-intersection-observer";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./LifeAtSampurnaComp.css";

import { Autoplay } from "swiper/modules";

import image1 from "../../resource/LifeAtSSD/IMG_20230526_152839.jpg";
import image3 from "../../resource/LifeAtSSD/WhatsApp Image 2022-11-14 at 5.12.50 PM (1).jpeg";
import image4 from "../../resource/LifeAtSSD/WhatsApp Image 2022-11-14 at 5.39.47 PM.jpeg";
import image5 from "../../resource/LifeAtSSD/WhatsApp Image 2023-06-15 at 12.59.32 PM.jpeg";

const LifeAtSSDComp = () => {
  const videoId = [
    {
      name: "Ruma Deb",
      designation: "Head - MIS",
      videoId: "QVCfDFZJO68",
    },
    {
      name: "Alpana Sil",
      designation: "Regional-Manager",
      videoId: "E78oFKvKvto",
    },
    {
      name: "Indira Roy",
      designation: "Senior L&D Manager",
      videoId: "Az5JpOf96fg",
    },
    {
      name: "Debi Dutta",
      designation: "Area Manager",
      videoId: "-lgQWDkZs84",
    },
    {
      name: "Pritam Dutta",
      designation: "Area Manager (Vocational Training)",
      videoId: "3lbM7mmaxn8",
    },
  ];

  return (
    <div className="container">
      <h4 className="headerText animate-slideup">Life @ Sampurna Skills</h4>
      {/* life@SSD intro */}
      <InView triggerOnce={true} threshold={0.1}>
        {({ inView, ref }) => (
          <div
            className={`intro transition-all ${
              inView ? "opacity-100 translate-y-0" : "translate-y-36 opacity-0"
            } duration-500`}
            ref={ref}
          >
            <div className="slide">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                lazy={"true"}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiper-slide bg-cover">
                  <img
                    src={image1}
                    loading="lazy"
                    alt="life@ssd"
                    className="slideImg w-full"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide bg-cover">
                  <img
                    src={image3}
                    loading="lazy"
                    alt="life@ssd"
                    className="slideImg"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide bg-cover">
                  <img
                    src={image4}
                    loading="lazy"
                    alt="life@ssd"
                    className="slideImg"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide bg-cover">
                  <img
                    src={image5}
                    loading="lazy"
                    alt="life@ssd"
                    className="slideImg"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="intro_text">
              <p className="text">
                At Sampurna Skills, the employee culture is characterized by a
                strong commitment to personal growth, collaborative teamwork,
                and a shared mission to empower communities through skill
                development. This culture Skills is one of dedication, growth,
                and community engagement, making it a dynamic and fulfilling
                place to work. Employees at Sampurna Skills are actively engaged
                with the communities they serve. This engagement not only helps
                in understanding the needs and challenges of the community but
                also fosters a sense of belonging and responsibility among the
                employees.
              </p>
            </div>
          </div>
        )}
      </InView>

      {/* youtube videos */}
      <div className="employee-speak">
        <InView triggerOnce={true} threshold={0.1}>
          {({ inView, ref }) => (
            <h4
              className={`employee-speak-title transition-all ${
                inView
                  ? "opacity-100 translate-y-0"
                  : "translate-y-36 opacity-0"
              } duration-500`}
              ref={ref}
            >
              What Our Employees Say About Our Values
            </h4>
          )}
        </InView>

        <InView triggerOnce={true} threshold={0.1}>
          {({ inView, ref }) => (
            <div
              className={`flex flex-wrap gap-y-4 sm:gap-3 md:gap-3 justify-start items-center w-full transition-all ${
                inView
                  ? "opacity-100 translate-y-0"
                  : "translate-y-36 opacity-0"
              } duration-500`}
              ref={ref}
            >
              {videoId.map((video) => {
                return (
                  <div
                    className="w-full h-36 sm:h-36 md:h-40 max-h-60 md:w-2/3 lg:w-1/3 aspect-video mx-auto overflow-hidden"
                    key={video.videoId}
                  >
                    <YouTube
                      videoId={video.videoId}
                      iframeClassName="employee-video object-cover w-full h-full rounded-md"
                      title="Video Title"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </InView>
      </div>
      <Link to={"/career"}>
        <button
          className="bg-[#ed1b69] block w-11/12 md:w-1/2 lg:w-1/3 mx-auto py-2.5 px-8 text-white font-semibold tracking-widest rounded-md cursor-pointer transition-all hover:bg-[#810b36] duration-200"
          type="button"
        >
          Join us
        </button>
      </Link>
      {/* employee gallery */}
      {/* <div className="employee-corner">
        <h4 className="headerText">Shining Stars</h4>
        <div className="employee-moments">
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>

          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LifeAtSSDComp;
