import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import router from "./router/router";
import Nav from "./Components/Navbar_component/Nav";
import Footer from "./Components/Footer_component/Footer";

export default function RootLayout() {
  const [allPaths, setAllPaths] = useState([]);
  const [hideNavBarFooter, setHideNavBarFooter] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const pathArr = [];
    router.routes[0].children.forEach((pathData) => {
      if (pathData.path !== "*") {
        pathArr.push(pathData.path);
      }
    });
    setAllPaths(pathArr);
  }, []);

  useEffect(() => {
    if (allPaths.length > 1) {
      setHideNavBarFooter(allPaths.includes(location.pathname));
    }
  }, [allPaths, location.pathname]);

  return (
    <div>
      {hideNavBarFooter && <Nav />}
        <Outlet />
      {hideNavBarFooter && <Footer />}
    </div>
  );
}
