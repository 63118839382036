import React from "react";
import "./TestimonialComp.css";
// import { Link } from "react-router-dom";
import { InView } from "react-intersection-observer";

const TestimonialComp = () => {
  return (
    <>
      <div className="testimonial">
        <h1 className="sec_heading">Success Speaks</h1>
        <div className="container">
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`testimonial_video ${
                  inView
                    ? "opacity-100 delay-0 translate-y-0"
                    : "translate-y-56  opacity-0"
                } duration-500`}
                ref={ref}
              >
                <iframe
                  src="https://www.youtube-nocookie.com/embed/W1A-adDYuLc"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </InView>
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`testimonial_video ${
                  inView
                    ? "opacity-100 delay-300 translate-y-0"
                    : "translate-y-56  opacity-0"
                } duration-500`}
                ref={ref}
              >
                <iframe
                  src="https://www.youtube-nocookie.com/embed/d9mNuN7vclo"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </InView>
          <InView triggerOnce={true} threshold={0.1}>
            {({ inView, ref }) => (
              <div
                className={`testimonial_video ${
                  inView
                    ? "opacity-100 delay-700 translate-y-0"
                    : "translate-y-56 opacity-0"
                } duration-500`}
                ref={ref}
              >
                <iframe
                  src="https://www.youtube-nocookie.com/embed/OA_VWXN_dP0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </InView>
        </div>
      </div>
    </>
  );
};

export default TestimonialComp;
