import React from "react";
import "./TargetComp.css";
import TagetPeople from "../../resource/Inner/Taget_people.jpeg"

const TargetComp = () => {
  return (
    <>
      <section className="target-people animate-slideup">
        <div className="container">
          <div className="targetplleft">
            <p>
              The target population of Sampurna Skills Development Pvt Ltd in
              the southern part of West Bengal would primarily include
              individuals and communities residing in the districts of South 24
              Parganas, Purba Medinipur, Paschim Medinipur, and Hooghly. This
              population would consist of people from diverse backgrounds,
              including low-income families, marginalized communities, students,
              and individuals seeking skill development opportunities.
            </p><br />
            <p>
              The target population may also include individuals looking to
              enhance their employability or pursue entrepreneurship in fields
              such as computer skills, makeup and beauty, and dress designing.
              Sampurna Skills Development Pvt Ltd aims to provide accessible and
              affordable skill development programs to empower individuals from
              the southern part of West Bengal, enabling them to improve their
              livelihoods, gain employment, or start their own businesses.
            </p>
          </div>
          <div className="targetplright">
            <img src={TagetPeople} alt="Target Peeople" />
          </div>
        </div>
      </section>
    </>
  );
};

export default TargetComp;
