import React, { useState, useEffect } from "react";
import "./GallaryComp.css";

import { InView } from "react-intersection-observer";

import IMG1 from "../../resource/Gallery/20230327_165706.jpg";
import IMG2 from "../../resource/Gallery/20230327_194059.jpg";
import IMG3 from "../../resource/Gallery/IMG_2646.jpg";
import IMG4 from "../../resource/Gallery/IMG_2712.jpg";
import IMG5 from "../../resource/Gallery/IMG_20220521_164219.jpg";
import IMG6 from "../../resource/Gallery/IMG_20230327_161706141_MF_PORTRAIT.jpg";
import IMG7 from "../../resource/Gallery/IMG-20220426-WA0014.jpg";
import IMG8 from "../../resource/Gallery/IMG20230327140928.jpg";
import IMG9 from "../../resource/Gallery/IMG20230327162947.jpg";
import IMG10 from "../../resource/Gallery/IMG20230327165652.jpg";
import IMG11 from "../../resource/Gallery/WhatsApp Image 2022-03-21 at 3.42.49 PM.jpeg";
import IMG12 from "../../resource/Gallery/WhatsApp Image 2022-03-21 at 3.43.37 PM.jpeg";
import IMG13 from "../../resource/Gallery/WhatsApp Image 2022-11-14 at 1.39.34 PM.jpeg";
import IMG14 from "../../resource/Gallery/WhatsApp Image 2022-11-14 at 1.39.35 PM.jpeg";
import IMG15 from "../../resource/Gallery/WhatsApp Image 2022-11-14 at 2.39.00 PM.jpeg";
import IMG17 from "../../resource/Gallery/IMG_20230526_152839.jpg";
import IMG18 from "../../resource/Gallery/WhatsApp Image 2023-06-15 at 12.59.32 PM.jpeg";
import IMG19 from "../../resource/Gallery/WhatsApp Image 2022-11-14 at 5.12.50 PM (1).jpeg";
import IMG20 from "../../resource/Gallery/WhatsApp Image 2022-11-14 at 5.39.47 PM.jpeg";
import IMG21 from "../../resource/Gallery/WhatsApp Image 2022-11-14 at 5.50.14 PM.jpeg";
import IMG22 from "../../resource/Gallery/WhatsApp Image 2023-05-25 at 1.55.45 PM.jpeg";
import IMG23 from "../../resource/Gallery/WhatsApp Image 2023-05-25 at 1.59.48 PM.jpeg";
import IMG24 from "../../resource/Gallery/20230609_173556.jpg";
import IMG25 from "../../resource/Gallery/Festive Minimal Happy Shri Ram Navami Instagram Post.png";
import IMG26 from "../../resource/Gallery/WhatsApp Image 2023-05-25 at 2.48.24 PM (1).jpeg";
import IMG27 from "../../resource/Gallery/WhatsApp Image 2023-05-25 at 2.48.29 PM.jpeg";
import IMG29 from "../../resource/Gallery/Untitled design (4).jpg";
import IMG31 from "../../resource/Gallery/hair tie.jpg";
import IMG32 from "../../resource/Gallery/topor_nailart.jpg";
import IMG33 from "../../resource/Gallery/IMG-20230114-WA0134.jpg";
import IMG34 from "../../resource/Gallery/Models representing different fashion cultures of India at 'Unity Utsav'.jpg";
import IMG35 from "../../resource/Gallery/1st Runner Up at 'Unity Utsav'.jpg";
import IMG36 from "../../resource/Gallery/Model and Actor Piyali Das as a Judge at 'Unity Utsav'.jpg";
import IMG37 from "../../resource/Gallery/Renowned Makeup Artist Subhas Biswas as a Judge at 'Unity Utsav'.jpg";
import IMG38 from "../../resource/Gallery/Winner of  'Unity Utsav', a fashion competition organised by Sampurna Skills Financial Service.jpg";
import IMG39 from "../../resource/Gallery/img39.jpg";
import IMG40 from "../../resource/Gallery/img40.jpg";
import IMG41 from "../../resource/Gallery/img41.jpg";
import IMG42 from "../../resource/Gallery/img42.jpg";
import IMG43 from "../../resource/Gallery/img43.jpg";
import IMG44 from "../../resource/Gallery/img44.jpg";
import IMG45 from "../../resource/Gallery/img45.jpg";
import IMG46 from "../../resource/Gallery/img46.jpg";
import IMG47 from "../../resource/Gallery/img47.jpg";
import IMG48 from "../../resource/Gallery/img48.jpg";
import loader from "../../resource/loader.svg";

const GallaryComp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);
  const [imageLoadArr, setImageLoadArr] = useState([]);

  const images = [
    {
      src: IMG39,
      title:
        "Emotions run high as judges and mentors gather on stage to honor the 2nd runner-up participant with their well-deserved award.",
    },
    {
      src: IMG40,
      title:
        "Gratitude and grace on and off the runway! 🙏💃🕺 Our stunning participants radiate joy and thankfulness after a dazzling performance. 🌟✨ Thank you for making the show unforgettable! ",
    },
    {
      src: IMG41,
      title:
        "Hon’ble Judges presenting the 1st runner-up award to the participants, surrounded by joyful smiles all around.",
    },
    {
      src: IMG42,
      title:
        "Honoring our valued partners! Our MD Sir, Mr. Abhijit Bera celebrates our cherished partners with heartfelt gratitude. ",
    },
    {
      src: IMG43,
      title:
        "Our distinguished judges bring brilliance and insight, making our fashion show truly exceptional. Thank you for your invaluable guidance and inspiration!",
    },
    {
      src: IMG44,
      title:
        "Our esteemed judges take the stage, sharing insights and inspiration at our fashion show. Their expertise lights up the runway and hearts alike!",
    },
    {
      src: IMG45,
      title:
        "Our fashion show participants shine brightly alongside their inspiring teachers, celebrating a journey of learning and style.",
    },
    {
      src: IMG46,
      title:
        "Our MD, Abhijit Bera, warmly welcoming the honorable judge Subhas Biswas. 🤝✨ It_s an honor to have such esteemed presence with us today!",
    },
    {
      src: IMG47,
      title:
        "Our MD, Mr. Abhijit Bera, warmly welcoming our esteemed and graceful judge, Piyali Das.",
    },
    {
      src: IMG48,
      title:
        "Our talented participants stand proud with their inspiring teachers, sharing the stage after a spectacular fashion show.",
    },
    {
      src: IMG38,
      title:
        "Winner of 'Unity Utsav', a fashion competition organised by Sampurna Skills Financial Service.",
    },
    {
      src: IMG34,
      title:
        "Models representing different fashion cultures of India at 'Unity Utsav'",
    },
    {
      src: IMG36,
      title: "Model and Actor Piyali Das as a Judge at 'Unity Utsav'",
    },
    {
      src: IMG37,
      title: "Renowned Makeup Artist Subhas Biswas as a Judge at 'Unity Utsav'",
    },
    {
      src: IMG35,
      title: "1st Runner Up at 'Unity Utsav'",
    },
    {
      src: IMG2,
      title:
        "A happy client flaunting her newly done nails at our Nair Art Stall organised in Bhawanipur Education Society's college festival.",
    },
    {
      src: IMG22,
      title:
        "A fashion forward Indian attire (Kurti) made as a demo for one of our Dress Designing classes. The fabric is store brought and the rest is made in our class from scratch.",
    },
    {
      src: IMG33,
      title:
        "A simplistic bridal look demonstration including makeup, hairstyle and draping. The model is a student ",
    }, // 4
    {
      src: IMG5,
      title:
        "A glimpse from one of our Computer practical classes. Here one of our trainers, is busy in class demonstration to our teenager group of students.",
    },
    {
      src: IMG4,
      title:
        "A reception look after a workshop arranged at Sampurna promoting apt training in various Makeup courses.",
    },
    {
      src: IMG20,
      title:
        "Students gathered in our Bagbazar branch. Our computer and Spoken English courses have modules apt for children from the age of 6 onwards.",
    },
    {
      src: IMG25,
      title: "A student created a beautiful mehendi art masterpiece.",
    },
    {
      src: IMG18,
      title: "Well-equipped computer classes and happy students",
    },
    {
      src: IMG11,
      title:
        "Students in practical computer classes. Students have the freedom to practice post classes when and if the classrooms are free.",
    },
    {
      src: IMG3,
      title: "We arranged a make-up and beauty workshop in one of our branch.",
    }, // 10 no   24
    {
      src: IMG9,
      title:
        " A fun moment at our Nair Art Stall organised in Bhawanipur Education Society’s college festival. Students getting their nails done, our team busy showcasing their talent and the above all the humble clown who agreed to pose for us made for a memory worthy moment.",
    },
    {
      src: IMG21,
      title:
        "A glimpse from Teachers’ Day celebration (2022) where students showcased their cultural talent as a form of paying respect towards the trainers. Here we can see one of our computer domain’s trainer Sandip Kayal (centre) with his students.",
    },
    {
      src: IMG23,
      title:
        "Masiha Tarannum Razi, Master Trainer, Dress Designing guiding students as they learn the process of dress drafting.",
    },
    {
      src: IMG26,
      title:
        "Students design mehendi on each other’s hands after gaining enough confidence practicing on white sheets",
    },
    {
      src: IMG10,
      title:
        "One of the happy clients after availing service from our nail art stall at the Bhawanipur Education Society’s college festival.",
    },
    {
      src: IMG19,
      title: "Students with our Makeup & Beauty trainer Fatma Khatoon",
    },
    {
      src: IMG15,
      title: "Students with our Makeup & Beauty trainer Priya Bera",
    }, // no 7
    {
      src: IMG14,
      title:
        "Students after receiving a small treat from our team on Children’s Day, 2022",
    },
    {
      src: IMG12,
      title:
        "Our Master Trainer of Computer domain Souvik Das on a practical session with the students",
    }, // 10 n  33
    {
      src: IMG24,
      title: "Beautiful kid's wear made by our skilled students.",
    }, // 9N    3
    {
      src: IMG27,
      title:
        "This is how elaborately we define a mehendi motif in our classes. The 'Dulha-Dulhan' motif demonstration, one of the trendiest motifs in the recent times, make our students more real world ready as mehendi artists.",
    },
    {
      src: IMG13,
      title:
        "Students getting ready for a group performance as an ice-breaking session for Teachers’ Day celebration (2022).",
    },
    {
      src: IMG7,
      title:
        "Students in practical computer classes. Students have the freedom to practice post classes when and if the classrooms are free.",
    },
    {
      src: IMG8,
      title:
        "Our Makeup and Beauty trainer Akhay Sarkar serving a student at the Bhawanipur Education Society’s college festival.",
    },
    {
      src: IMG31,
      title:
        "In class demonstration of semi-bridal/reception hairstyle. This particular hairstyle is known as mermaid bun.",
    },
    {
      src: IMG17,
      title:
        "Computer trainer is supervising the class and assisting students in clearing their doubts.",
    },
    {
      src: IMG32,
      title:
        "Nail art apt for Bengali wedding made by our trainer Akhay Sarkar",
    },
    {
      src: IMG6,
      title:
        "A happy client flaunting her newly done nails at our Nair Art Stall organised in Bhawanipur Education Society’s college festival.",
    },
    {
      src: IMG29,
      title: "Trainee conducted a stunning but simple bridal makeover.",
    },
    {
      src: IMG1,
      title:
        "A happy client flaunting her newly done nails at our Nair Art Stall organised in Bhawanipur Education Society's college festival.",
    },
  ];

  const handleImageClick = (index) => {
    setShowPopup(true);
    setPopupIndex(index);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleNext = () => {
    setPopupIndex((popupIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setPopupIndex((popupIndex + images.length - 1) % images.length);
  };

  const handleImageOnLoad = (index) => {
    setImageLoadArr((prevArr) => {
      const imageLoadedIndexArr = [...prevArr];
      imageLoadedIndexArr[index] = true;
      return imageLoadedIndexArr;
    });
  };

  useEffect(() => {
    setImageLoadArr(Array(images.length).fill(false));
  }, [images.length]);

  return (
    <>
      <div className="gallery container">
        <div className="box">
          {images.map((image, index) => {
            return (
              <InView key={index} triggerOnce={true}>
                {({ inView, ref }) => (
                  <div
                    className={`image-container transition-all ${
                      inView
                        ? "opacity-100 translate-y-0"
                        : "translate-y-44 opacity-0"
                    } duration-500 ${
                      inView && imageLoadArr[index]
                        ? ""
                        : "h-96 w-64 border-2 transiton-all duration-700 flex items-center justify-center"
                    } duration-500`}
                    key={index}
                    ref={ref}
                  >
                    {inView ? (
                      <img
                        src={inView ? image.src : ""}
                        alt="gallery_image"
                        onLoad={() => handleImageOnLoad(index)}
                        onClick={() => handleImageClick(index)}
                      />
                    ) : (
                      <img
                        src={loader}
                        alt="Loader"
                        className="w-full h-52 object-contain object-center"
                      />
                    )}
                  </div>
                )}
              </InView>
            );
          })}
        </div>
        {showPopup && (
          <div className="gallery-popup-img">
            <span onClick={handleClosePopup}>&times;</span>
            <div className="popup-main">
              <button onClick={handlePrevious}>
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <div className="popup-content">
                <img src={images[popupIndex].src} alt="pic" />
                <p>{images[popupIndex].title}</p>
              </div>
              <button onClick={handleNext}>
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GallaryComp;
