import React, { useEffect, useState } from "react";
import "./ComputerComp.css";
import axios from "axios";

const ComputerComp = () => {
  const [coursedata, setCoursedata] = useState([]);
  const [contentVisibility, setContentVisibility] = useState([]);

  const loadcoursedata = async () => {
    const response = await axios.get("https://manage.sampurnaskills.co.in/api/Computer");
    setCoursedata(response.data);
    setContentVisibility(new Array(response.data.length).fill(false));
  };

  useEffect(() => {
    loadcoursedata();
  }, []);

  const toggleContent = (index) => {
    setContentVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  return (
    <>
      <section className="computer_content animate-slideup">
        <div className="container">
          <div className="domain_intro">
            <p>
              Sampurna Skills offers a comprehensive range of computer courses
              designed to provide participants with practical skills and
              knowledge in various areas of computer technology. The courses
              cover a wide range of topics such as programming languages,
              software development, database management, web development,
              networking, cybersecurity, and more. Whether you're a beginner
              looking to learn the basics of computer operation or an
              experienced professional seeking to enhance your technical
              expertise, Sampurna's computer courses cater to individuals with
              different skill levels and career goals. With hands-on training,
              industry-relevant curriculum, and experienced instructors,
              Sampurna's computer courses are designed to equip participants
              with the necessary skills and knowledge to excel in today's
              technology-driven world.
            </p>
          </div>
          <div className="course_div">
            {coursedata.map((item, index) => {
              return (
                <article key={item.id} className="course">
                  <div className="course_content">
                    <div className="course_contant_header">
                      <h4>{item.name}</h4>
                      <div
                        className="course_icon"
                        onClick={() => toggleContent(index)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    </div>
                    <div
                      className={`course_content_details ${
                        contentVisibility[index] ? "show" : "hide"
                      }`}
                    >
                      <p className="course_intro">{item.course_info}</p>
                      <h3 className="critaria">Eligibility Criteria:</h3>
                      <p className="critaria_content">{item.eligibility_critaria}</p>
                      <div className="duration">
                        <h3>Course Duration: </h3>
                        <p>{item.course_duration}</p>
                      </div>
                      <div className="course-highlight">
                        <h3>Syllabus highlights: </h3>
                        <p>{item.syllabus_highlight}</p>
                      </div>
                      <div className="course-fees">
                        <h3>Fee structure: </h3>
                        <p>{item.fees_structure}</p>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ComputerComp;
