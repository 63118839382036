import React, { useEffect, useState } from "react";
import axios from "axios";

const SpokenComp = () => {
  const [coursedata, setCoursedata] = useState([]);
  const [contentVisibility, setContentVisibility] = useState([]);

  const loadcoursedata = async () => {
    const response = await axios.get("https://manage.sampurnaskills.co.in/api/Spoken");
    setCoursedata(response.data);
    setContentVisibility(new Array(response.data.length).fill(false));
  };

  useEffect(() => {
    loadcoursedata();
  }, []);

  const toggleContent = (index) => {
    setContentVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  return (
    <>
      <section className="spoken_content animate-slideup">
        <div className="container">
          <div className="domain_intro">
            <p>
            Welcome to Sampurna! Your go-to destination for mastering spoken English. Whether you're a beginner or an advanced learner, our domain is designed to enhance your communication skills and boost your confidence. With a curated collection of interactive lessons, practical exercises, and real-life scenarios, we'll help you navigate conversations, improve pronunciation, expand vocabulary, and develop fluency. Our expert instructors are dedicated to guiding you every step of the way, ensuring an engaging and enriching learning experience. Get ready to unlock your full potential and become a fluent English speaker with Sampurna!
            </p>
          </div>
          <div className="course_div">
            {coursedata.map((item, index) => {
              return (
                <article key={item.id} className="course">
                  <div className="course_content">
                    <div className="course_contant_header">
                      <h4>{item.name}</h4>
                      <div
                        className="course_icon"
                        onClick={() => toggleContent(index)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    </div>
                    <div
                      className={`course_content_details ${
                        contentVisibility[index] ? "show" : "hide"
                      }`}
                    >
                      <p className="course_intro">{item.course_info}</p>
                      <h3 className="critaria">Eligibility Criteria:</h3>
                      <p className="critaria_content">
                        {item.eligibility_critaria}
                      </p>
                      <div className="duration">
                        <h3>Course Duration: </h3>
                        <p>{item.course_duration}</p>
                      </div>
                      <div className="course-highlight">
                        <h3>Syllabus highlights: </h3>
                        <p>{item.syllabus_highlight}</p>
                      </div>
                      <div className="course-fees">
                        <h3>Fee structure: </h3>
                        <p>{item.fees_structure}</p>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default SpokenComp;
