import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const year = new Date().getFullYear();

const Footer = () => {
  return (
    <>
      <footer className="foot">
        <div className="container">
          <div className="company_info group">
            <h1>Main Potral</h1>
            <Link to="https://sampurna.co.in/" target="_blank">
              Sampurna Group Of Companies
            </Link>
            <div className="quicklink">
              <p>
                Sampurna is a social-microfinance organization that aims to
                foster wholesome development of the comparatively vulnerable
                members of our society.
              </p>
            </div>
          </div>
          <div className="company_info finance">
            <h1>Financial Services</h1>
            <Link to="https://sampurnafinance.co.in" target="_blank">
              Sampurna Financial Services <br /> Private Limited
            </Link>
            <div className="quicklink">
              <p>
                Sampurna Financial Services Pvt. Ltd. is a business
                correspondence dedicated to serving low-income groups with
                microloans and group loans. Sampurna Finance provides access to
                credit for individuals and groups who often face limited
                opportunities for borrowing.
              </p>
            </div>
          </div>
          <div className="company_info traning">
            <h1>Social Services</h1>
            <Link to="https://stepindia.in" target="_blank">
              Sampurna Training And Entrepreneurship Programme
            </Link>
            <div className="quicklink">
              <p>
                Sampurna Training and Entrepreneurship Programme is a non-profit
                entity working towards the betterment of the low income families
                through various social initiatives and opportunities.
              </p>
            </div>
          </div>
          <div className="company_info address">
            <h1>Get in touch</h1>
            <ul>
              <Link
                to={"https://maps.app.goo.gl/WhEjaXoj8dB6avT38"}
                target="_blank"
              >
                <li
                  style={{
                    width: "100%",
                    height: "content-fit",
                    display: "flex",
                  }}
                >
                  <i className="fa-solid whatsapp fa-map-location-dot" style={{ marginTop : '3px' }}></i>
                  &nbsp;&nbsp;
                  <span  style={{ fontWeight : "bold" }}>
                    3rd Floor (North), DN-30, Sector V, Salt Lake, Kolkata-700091.
                  </span>
                </li>
              </Link>
              <Link to={"tel:+03335081849"}>
                <li
                  style={{
                    width: "100%",
                    height: "content-fit",
                    display: "flex",
                  }}
                >
                  <i className="fa-solid whatsapp fa-headset" style={{ marginTop : '3px' }}/>
                  &nbsp;&nbsp;
                  <span style={{ fontWeight : "bold" }}> 
                    Customer Grievance No. <br />
                    (033) 3508-1849
                  </span>
                </li>
              </Link>
              <Link to={"https://wa.me/+918100971969"} target="_blank">
                <li style={{
                    width: "100%",
                    height: "content-fit",
                    display: "flex",
                    fontWeight : "bold"
                  }}>
                  <i className="fa-brands whatsapp fa-whatsapp" style={{ marginTop : '1px', marginRight : "7px" }} /><span> +91 81009-71969</span>
                </li>
              </Link>
              <li>
                <Link
                  to={
                    "https://www.facebook.com/stepmicrofinanceindia?mibextid=JRoKGi"
                  }
                  target="_blank"
                  className="social-link"
                >
                  <i
                    className="fa-brands fa-facebook-f"
                    style={{ color: "#316FF6" }}
                  ></i>
                </Link>
                <Link
                  to={"https://www.youtube.com/@sampurna6984"}
                  target="_blank"
                  className="social-link"
                >
                  <i
                    className="fa-brands fa-youtube"
                    style={{ color: "#c4302b" }}
                  ></i>
                </Link>
                <Link
                  to={
                    "https://www.instagram.com/sampurna_projects/?igsh=MXJ5ZGhtcTEzOWo4cQ%3D%3D"
                  }
                  target="_blank"
                  className="social-link"
                >
                  <i
                    className="fa-brands fa-instagram"
                    style={{ color: "#C13584" }}
                  ></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <section className="copyright_policy">
        <div className="container">
          <div className="copyright">
            <p>
              &#169; {year} <Link to="/">SAMPURNASkills</Link>. All Rights
              Reserved
            </p>
          </div>
          <div className="policy mx-2 flex flex-wrap text-sm gap-x-2 sm:gap-x-4">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cancel-policy">Cancellation Policy</Link>
            <Link to="/refund-policy">Refund Policy</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
