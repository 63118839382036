import React from 'react';
import "./VisionComp.css";

const VisionCom = () => {
  return (
    <>
      <section className="vision_mission container">
        <div className="vision_content animate-slideup">
            <h1>Vision</h1>
            <p>A flourishing society where vulnerable families are empowered to improve and strengthen their standard of living.</p>
        </div>
        <div className="mission_content animate-slideup">
            <h1>Mission</h1>
            <p>To provide low income and disadvantaged families with sustainable adapted and innovative financial, business development, access to employment and social services, building their self-reliance and capacity to realize their wishes</p>
        </div>
      </section>
    </>
  )
}

export default VisionCom;
