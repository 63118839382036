import React, { useEffect, useState } from "react";
import axios from "axios";

const DressComp = () => {
  const [coursedata, setCoursedata] = useState([]);
  const [contentVisibility, setContentVisibility] = useState([]);

  const loadcoursedata = async () => {
    const response = await axios.get(
      "https://manage.sampurnaskills.co.in/api/Dress"
    );
    setCoursedata(response.data);
    setContentVisibility(new Array(response.data.length).fill(false));
  };

  useEffect(() => {
    loadcoursedata();
  }, []);

  const toggleContent = (index) => {
    setContentVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  return (
    <>
      <section className="dress_content animate-slideup">
        <div className="container">
          <div className="domain_intro">
            <p>
              The Certificate in Basic Dress Designing is a specialized program
              that focuses on developing fundamental skills in dress design and
              construction. This course covers key concepts such as pattern
              drafting, fabric selection, garment construction techniques, and
              basic fashion illustration. Participants will gain hands-on
              experience in creating basic dress designs, understanding body
              measurements, and executing sewing techniques. The program aims to
              provide individuals with the foundation to pursue a career in
              dressmaking or enhance their skills in fashion design.
            </p>
          </div>
          <div className="course_div">
            {coursedata.map((item, index) => {
              return (
                <article key={item.id} className="course">
                  <div className="course_content">
                    <div className="course_contant_header">
                      <h4>{item.name}</h4>
                      <div
                        className="course_icon"
                        onClick={() => toggleContent(index)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    </div>
                    <div
                      className={`course_content_details ${
                        contentVisibility[index] ? "show" : "hide"
                      }`}
                    >
                      <p className="course_intro">{item.course_info}</p>
                      <h3 className="critaria">Eligibility Criteria:</h3>
                      <p className="critaria_content">
                        {item.eligibility_critaria}
                      </p>
                      <div className="duration">
                        <h3>Course Duration: </h3>
                        <p>{item.course_duration}</p>
                      </div>
                      <div className="course-highlight">
                        <h3>Syllabus highlights: </h3>
                        <p>{item.syllabus_highlight}</p>
                      </div>
                      <div className="course-fees">
                        <h3>Fee structure: </h3>
                        <p>{item.fees_structure}</p>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default DressComp;
