import React from "react";
import "./CourseComp.css";
import { Link } from "react-router-dom";
import { InView } from "react-intersection-observer";

const CourseComp = () => {
  return (
    <>
      <section className="services">
        <InView triggerOnce={true} threshold={0}>
          {({ inView, ref }) => (
            <h1
              className={`sec_heading transition-all ${
                inView
                  ? "opacity-100  translate-y-0"
                  : "opacity-0  translate-y-48"
              } duration-700`}
              ref={ref}
            >
              Our Courses
            </h1>
          )}
        </InView>

        <div className="container">
          <InView       
            threshold={0}
            triggerOnce={true}
          >
            {({ inView, ref }) => (
              <Link
                className={`service_box transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-40"
                } duration-700`}
                to="/Computer"
                ref={ref}
              >
                <div className="service_content">
                  <h5>Computer</h5>
                  <p>
                    Sampurna Skills offers a comprehensive range of computer
                    courses designed to provide participants with practical
                    skills and knowledge in various areas of computer
                    technology.
                  </p>
                  <span>LEARN MORE</span>
                </div>
              </Link>
            )}
          </InView>
          <InView
            threshold={0}
            triggerOnce={true}
          >
            {({ inView, ref }) => (
              <Link
                className={`service_box transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-48"
                } duration-700`}
                to="/MakeupBeauty"
                ref={ref}
              >
                <div className="service_content">
                  <h5>Makeup & Beauty</h5>
                  <p>
                    Sampurna offers a diverse range of makeup and beauty courses
                    designed to provide participants with professional skills in
                    the beauty industry.
                  </p>
                  <span>LEARN MORE</span>
                </div>
              </Link>
            )}
          </InView>
          <InView   
            threshold={0}
            triggerOnce={true}
          >
            {({ inView, ref }) => (
              <Link
                className={`service_box transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-56"
                } duration-700`}
                to="/DressDesigining"
                ref={ref}
              >
                <div className="service_content">
                  <h5>Dress Designing</h5>
                  <p>
                    The Certificate in Basic Dress Designing is a specialized
                    program that focuses on developing fundamental skills in
                    dress design and construction.{" "}
                  </p>
                  <span>LEARN MORE</span>
                </div>
              </Link>
            )}
          </InView>
          <InView   
            threshold={0}
            triggerOnce={true}
          >
            {({ inView, ref }) => (
              <Link
                className={`service_box transition-all ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0  translate-y-64"
                } duration-700`}
                to="/SpokenEnglish"
                ref={ref}
              >
                <div className="service_content">
                  <h5>Spoken English</h5>
                  <p>
                    The Certificate in Basic Dress Designing is a specialized
                    program that focuses on developing fundamental skills in
                    dress design and construction.{" "}
                  </p>
                  <span>LEARN MORE</span>
                </div>
              </Link>
            )}
          </InView>
        </div>
      </section>
    </>
  );
};

export default CourseComp;
