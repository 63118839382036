import React, { useEffect, useState } from "react";
import "./WeProvideComp.css";
import axios from "axios";
import { InView } from "react-intersection-observer";

const WeProvideComp = () => {
  const [data, setData] = useState([]);
  const loadData = async () => {
    const response = await axios.get(
      "https://manage.sampurnaskills.co.in/api/get"
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      <section className="weprovide">
        <InView triggerOnce={true} threshold={0}>
          {({ inView, ref }) => (
            <h1
              className={`sec_heading ${
                inView
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-48"
              } duration-700`}
              ref={ref}
            >
              What We Provide
            </h1>
          )}
        </InView>

        <div className="container">
          <InView
            triggerOnce={true}
            threshold={0.4}
            trackVisibility={true}
            delay={500}
          >
            {({ inView, ref }) => (
              <div
                className={`provide_left ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-48"
                } duration-700`}
                ref={ref}
              >
                <div className="card_icons">
                  {data.map((item, index) => {
                    return (
                      <div key={item.id} className="card_item">
                        <i className={item.icons}></i>
                        <span>{item.data}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="card_img">
                  <div className="imgbox">
                    <span>Computer</span>
                  </div>
                  <div className="imgbox">
                    <span>Makeup & Beauty</span>
                  </div>
                  <div className="imgbox">
                    <span>Dress Desigining</span>
                  </div>
                  <div className="imgbox">
                    <span>Spoken English</span>
                  </div>
                </div>
              </div>
            )}
          </InView>

          <InView
            triggerOnce={true}
            threshold={0.4}
            trackVisibility={true}
            delay={500}
          >
            {({ inView, ref }) => (
              <div
                className={`provide_right ${
                  inView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-48"
                } duration-700`}
                ref={ref}
              >
                <p>
                  Presently, we have four operational topics namely&nbsp;
                  <span className="highlight">
                    Beauty, Computer, Spoken English and Apparel
                  </span>
                  &nbsp;with effective courses. <br /> <br />
                  For Spoken English the eligible age group is 5 to 40 years
                  whereas for the other courses, students can be between 14 to
                  40 years of age.
                </p>
              </div>
            )}
          </InView>
        </div>
      </section>
    </>
  );
};

export default WeProvideComp;
