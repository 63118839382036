import React from "react";
import "./Home_OverviewComp.css";
import SkillImg from "../../resource/Inner/Skill.jpg";
import { useInView } from "react-intersection-observer";


const HomeOverviewComp = () => {
  const { ref, inView } = useInView({
    triggerOnce : true,
    threshold: 0,
  });
  return (
    <>
      <section className={`overviewcontent container transition-all ${
                inView
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-48"
              } duration-700`} ref={ref}>
        <div className="paragraph">
          <h1 className="sec_heading">Overview</h1>
          <p>
            Sampurna believes in providing members of the lower income group of
            our society with skill developmental training programmes to support
            them in healing their root cause of poor living standards by helping
            them realize their interests is also imperative. We have witnessed
            that with a small impetus to awaken their entrepreneurial intent and
            skills; and with a little push, they gain the ability to jump over
            the traditional 'poverty trap'. Sampurna has enabled hundreds of
            families to dream and eventually turn those dreams into reality.
          </p>
        </div>
          <div className="overview_img">
            <img src={SkillImg} alt="Sills" />
          </div>
      </section>
    </>
  );
};

export default HomeOverviewComp;
