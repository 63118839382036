import React, { useState, useEffect, useRef } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import logo from "../../resource/logo.png";
import Brochure from "../../resource/PDF/Brochure.pdf";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [expandMenuSectionName, setExpandeMenuSectionName] = useState("");
  const location = useLocation();
  
  const phoneMenuRef = useRef(null)
  const mobileIconRef = useRef(null)

  const expnadeMenuFn = (menuName) => {
    if(expandMenuSectionName === menuName){
      setExpandeMenuSectionName("");
      return
    }
    setExpandeMenuSectionName(menuName)
  };

  //hooks that run for changing location.path except first render
  const useEffectAfterFirstRender = (effect, dependency) => {
    const hasMounted = useRef(false)
    useEffect(() => {
      if(hasMounted.current){
        effect()
      }else{
        hasMounted.current = true;
      }
    }, dependency)
  }
  
  useEffectAfterFirstRender(() => {
    phoneMenuRef.current.classList.remove('phone_menu_item_active')
    mobileIconRef.current.classList.remove('fa-xmark')
  }, [location.pathname])

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    // if (window.innerWidth >= 769) {
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollBtn = document.getElementById("mybutton");
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        scrollBtn.style.display = "block";
      } else {
        scrollBtn.style.display = "none";
      }
    }

    scrollBtn.onclick = function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
  }, []);

  useEffect(() => {
    let Menu_open = document.querySelector(".menu_btn");
    let Mobile_menu = document.querySelector(".phone_menu_item");
    Menu_open.onclick = function () {
      Mobile_menu.classList.toggle("phone_menu_item_active");
      Menu_open.classList.toggle("fa-xmark");
    };
  });

  return (
    <>
      <div className="head">
        <div className="container">
          <div className="nav-items">
            <div className="head_left">
              <Link to="/career">Career</Link>
              <Link to="/our-center">Our Centres</Link>
              <Link to="/faq">FAQ</Link>
              <div className="head_left_report_tag">
                Reports &nbsp;{" "}
                <i
                  style={{ color: "#fff" }}
                  className="fa-solid changei fa-caret-down"
                ></i>
                <div className="AnnualyReports">
                  <Link to="/quarter-report">Quarterly Reports</Link>
                  {/* <Link to="/annual-report">Annualy Reports</Link> */}
                </div>
              </div>
            </div>
            <div className="head_right">
              <Link to="tel:03335081849/">
                <i className="fa-solid fa-headset"></i>
                <span style={{ color: "white" }}>
                  Customer Grievance Number:{" "}
                </span>
                <span className="icon_padding" style={{ color: "white" }}>
                  (033) 3508-1849
                </span>
              </Link>

              <i className="fa-solid fa-grip-lines-vertical"></i>

              <Link
                to="https:///mail.google.com/mail/?view=cm&fs=1&to=contactus@sampurna.co.in"
                target="_blank"
              >
                <i className="fa-regular fa-envelope"></i>
                <span className="icon_padding">contactus@sampurna.co.in</span>
              </Link>

              <i className="fa-solid fa-grip-lines-vertical"></i>

              <Link to="tel:6292276444/">
                <i className="fa-solid fa-phone"></i>
                <span className="icon_padding">+91 62922-76444</span>
              </Link>

              <i className="fa-solid fa-grip-lines-vertical"></i>

              <Link className="brochure" to={Brochure} target="_blank">
                Download Brochure
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id="mybutton" className="scroll_top cursor-pointer">
        <i className="fa-solid fa-arrow-up"></i>
      </div>

      <nav className={isSticky ? "fixed-position" : "nav"}>
        <div className="container">
          <div className="brand">
            <Link to="/">
              <img className="logowhite" src={logo} alt="Sampurna Skills" />
            </Link>
          </div>
          <div className="menu">
            <ul>
              <li className="menu_item_">
                <Link
                  to={"#"}
                  className="menu_link"
                  style={{ color: isSticky ? "var(--indigo)" : "white" }}
                >
                  About Us
                </Link>
                <i className="fa-solid changei fa-caret-down" style={{ color: isSticky ? "var(--indigo)" : "white" }}></i>
                <ul>
                  <li>
                    <Link to="/overview">Overview</Link>
                  </li>
                  <li>
                    <Link to="/vision">Vision & Mission</Link>
                  </li>
                  <li>
                    <Link to="/target-people">Target People</Link>
                  </li>
                  <li>
                    <Link to="/organization-background">
                      Organization Background
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link
                  to={"#"}
                  className="menu_link"
                  style={{ color: isSticky ? "var(--indigo)" : "white" }}
                >
                  Our Courses
                </Link>
                <i className="fa-solid changei fa-caret-down" style={{ color: isSticky ? "var(--indigo)" : "white" }}></i>
                <ul>
                  <li>
                    <Link to="/computer">Computer</Link>
                  </li>
                  <li>
                    <Link to="/makeup-beauty">Beauty & Makeup</Link>
                  </li>
                  <li>
                    <Link to="/dress-designing">Dress Designing</Link>
                  </li>
                  <li>
                    <Link to="/spoken-english">Spoken English</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link to={"#"} className="menu_link" style={{ color: isSticky ? "var(--indigo)" : "white" }}>
                  Governance
                </Link>
                <i className="fa-solid changei fa-caret-down" style={{ color: isSticky ? "var(--indigo)" : "white" }}></i>
                <ul>
                  <li>
                    <Link to="/board">Board</Link>
                  </li>
                  <li>
                    <Link to="/management">Management Team</Link>
                  </li>
                  <li>
                    <Link to="/grievance-redressal">Grievance Redressal</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link to="/gallery" className="menu_link" style={{ color: isSticky ? "var(--indigo)" : "white" }}>
                  Gallery
                </Link>
              </li>
              <li className="menu_item_">
                <Link to="/life-at-sampurna" className="menu_link" style={{ color: isSticky ? "var(--indigo)" : "white" }}>
                  Life@Sampurna
                </Link>
              </li>
              <li className="menu_item_">
                <Link to="/contact" className="menu_link" style={{ color: isSticky ? "var(--indigo)" : "white" }}>
                  Get In Touch
                </Link>
              </li>
            </ul>
          </div>
          <div className="menu-btn">
            <i className="fa-solid menu_btn fa-bars" ref={mobileIconRef}></i>
          </div>

          <div className="phone_menu_item bg-black/95 h-screen" ref={phoneMenuRef}>
            {/* -------------------Menus for mobile screen------------------------ */}
            <Link to="#" onClick={() => expnadeMenuFn("aboutus")}>
              <strong>ABOUT US</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <section
              className={`w-full transition-all ${
                 expandMenuSectionName === "aboutus"
                  ? "flex flex-col animate-expandmenu origin-top"
                  : "hidden"
              } duration-150`}
            >
              <Link to="/overview">Overview</Link>
              <Link to="/vision">Vision & Mission</Link>
              <Link to="/target-people">Target People</Link>
              <Link to="/organization-background">Organization Background</Link>
            </section>
            {/* )} */}

            {/* -----------------------Our Courses Menu-------------------------- */}
            <Link to="#" onClick={() => expnadeMenuFn("ourcourses")}>
              <strong>Our Courses</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <section
              className={`w-full transition-all ${
                 expandMenuSectionName === "ourcourses"
                  ? "flex flex-col animate-expandmenu origin-top"
                  : "hidden"
              } duration-150`}
            >
              <Link to="/computer">Computer</Link>
              <Link to="/makeup-beauty">Beauty & Makeup</Link>
              <Link to="/dress-designing">Dress Designing</Link>
              <Link to="/spoken-english">Spoken English</Link>
            </section>

              {/* ----------------------Governance---------------------------- */}
            <Link to="#" onClick={() => expnadeMenuFn("governance")}>
              <strong>GOVERNANCE</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <section className={`w-full transition-all ${
                 expandMenuSectionName === "governance"
                  ? "flex flex-col animate-expandmenu origin-top"
                  : "hidden"
              } duration-150`}>
              <Link to="/board">Board</Link>
              <Link to="/management">Management Team</Link>
              <Link to="/grievance-redressal">Grievance Redressal</Link>
            </section>
            {/* -----------------------Gallery---------------------------------- */}
            <Link to="/gallery">
              <strong>GALLERY</strong>
            </Link>
            {/* -------------------------Life At Sampurna------------------------------- */}
            <Link to="/life-at-sampurna">
              <strong>Life@Sampurna</strong>
            </Link>
            {/* ------------------------------Get In Touch----------------------------------------- */}
            <Link to="/contact">
              <strong>Get in Touch</strong>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
