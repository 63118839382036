import React from "react";
import "./FAQComp.css";
import { Link } from "react-router-dom";

const FAQComp = () => {
  const handleClick = (event) => {
    const faq = event.currentTarget.closest(".faq");
    faq.classList.toggle("open");
    const answer = faq.querySelector(".answer");
    answer.style.display = answer.style.display === "block" ? "none" : "block";
  };

  return (
    <>
      <section className="faqs">
      {/* faqs_container */}
        <div className="container max-w-7xl mx-auto divide-y-2">
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>
                How is Sampurna skills training better than other institutes?
              </h4>
              <p className="answer">
                We have student appreciated effective counselling sessions,
                market aligned courses, affordable fees, experienced trainers.
                We also provide with equipment (no extra cost added to the
                course fees) and placement assistance.
              </p>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>
                Who can apply for the Skill Development Training programs?
              </h4>
              <p className="answer">
                School students, educationally disadvantaged candidates like
                school dropouts, academically less oriented, undergraduates with
                the need for skill enhancement & skill up-gradation, unemployed
                youngsters, economically weaker section of society. Age
                criteria: 5 to 40 years.
              </p>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>Where are the centres located?</h4>
              <div className="answer">
                <p className="upheading">
                  At present, our total no of centres is 22, spread over 7
                  different districts.
                </p>
                <ol>
                  <li>
                    <strong>Kolkata:</strong> Bagbazar, Kadapara, Bondelgate
                  </li>
                  <li>
                    <strong>Howrah:</strong> Howrah Maidan, Ghusuri
                  </li>
                  <li>
                    <strong>North 24 parganas:</strong> Kamarhati, Bidhan nagar
                  </li>
                  <li>
                    <strong>Hooghly:</strong> Siyakhala
                  </li>
                  <li>
                    <strong>West Medinipur:</strong> Chandrakona, Medinipur
                    Town, Daspur, Khirpai, Sabang
                  </li>
                  <li>
                    <strong>East Medinipur:</strong> Kolaghat, Mayna, Egra,
                    Keshary
                  </li>
                  <li>
                    <strong>South 24 Parganas:</strong> Baruipur,
                    Lakshikantapur, Raidighi, Canning, Dimond Harbour
                  </li>
                </ol>
              </div>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>How you can join a course?</h4>
              <div className="answer">
                <h3>
                  Candidates can directly approach the nearest Training centres
                  or call our toll-free number. Interested candidates can also
                  connect with us through our social media sites.
                </h3>{" "}
                <br /> Apply Now |
                <Link
                  to="https://www.facebook.com/SampurnaSkillsDevelopment"
                  target="_blank"
                >
                  Sampurna Facebook Page
                </Link>
                | Toll Free Number: +91-6292 276 444
              </div>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>What is the duration of a course?</h4>
              <p className="answer">
                Certificate courses' duration: minimum 2 months Diploma courses'
                duration: maximum 6-12 months
              </p>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>What are the Infrastructure facilities available?</h4>
              <p className="answer">
                All Training Centres are equipped with excellent infrastructure
                facilities (Smart TV, AC Lab, 1:1 Student Computer ratio,
                Individual lab for different courses) to complete the
                teaching-learning process and are continuously upgraded and
                modernized to keep up with the needs of the present industry
                standards.
              </p>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>How are the assessment & certification done?</h4>
              <p className="answer">
                Continuous assessment is being done during the course.
                Successful completion of the course, certification is issued to
                the pass-out candidates within one month of course completion
                which is coordinated by Training Managers and Vocational
                Training Executives.
              </p>
            </div>
          </article>
          <article className="faq rounded-md transition-all bg-black/90 hover:bg-black/95 duration-300" onClick={handleClick}>
            <div className="faq_icon mt-2">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="question_answer">
              <h4>Is there any placement support?</h4>
              <p className="answer">
                After successful completion of the course, students will get
                End-to-End career guidance and assistance.
              </p>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default FAQComp;
