import React from "react";
import { createBrowserRouter } from "react-router-dom";

import RootLayout from '../RootLayout'
import Home from '../Components/Pages/Home'
import Career from "../Components/Pages/Career";
import OurCenter from "../Components/Pages/OurCenter";
import NotFound from "../Components/Pages/NotFound";
import FAQ from "../Components/Pages/FAQ";
import QuarterlyReport from "../Components/Pages/QuarterlyReport";
import AnnualReport from "../Components/Pages/AnnualReport";
import Overview from "../Components/Pages/Overview";
import Vision from "../Components/Pages/Vision";
import TargetPeople from "../Components/Pages/TargetPeople";
import OrganizationBackground from "../Components/Pages/OrganizationBackground";
import Computer from "../Components/Pages/Computer";
import Makeup from "../Components/Pages/Makeup";
import Dress from "../Components/Pages/Dress";
import Spoken from "../Components/Pages/Spoken";
import Gallary from "../Components/Pages/Gallary";
import LifeAtSSD from "../Components/Pages/LifeAtSSD";
import Board from "../Components/Pages/Board";
import Management from "../Components/Pages/Management";
import GrievanceRedressal from "../Components/Pages/GrievanceRedressal";
import Contact from "../Components/Pages/Contact";
import PrivacyPolicy from "../Components/Pages/PrivacyPolicy";
import CancellationPolicy from "../Components/Pages/CancellationPolicy";
import RefundPolicy from "../Components/Pages/RefundPolicy";

const router = createBrowserRouter([
    {
        path : "",
        element : <RootLayout />,
        children : [
            {
                path : "/",
                element : <Home />
            },
            {
                path : "/career",
                element : <Career />
            },
            {
                path : "/our-center",
                element : <OurCenter />
            },
            {
                path : "/faq",
                element : <FAQ />
            },
            {
                path : "/quarter-report",
                element : <QuarterlyReport />
            },
            {
                path : "/annual-report",
                element : <AnnualReport />
            },
            {
                path : "/overview",
                element : <Overview />
            },
            {
                path : "/vision",
                element : <Vision />
            },
            {
                path : "/target-people",
                element : <TargetPeople />
            },
            {
                path : "/organization-background",
                element : <OrganizationBackground />
            },
            {
                path : "/computer",
                element : <Computer />
            },
            {
                path : "/makeup-beauty",
                element : <Makeup />
            },
            {
                path : "/dress-designing",
                element : <Dress />
            },
            {
                path : "/spoken-english",
                element : <Spoken />
            },
            {
                path : "/gallery",
                element : <Gallary />
            },
            {
                path : "/life-at-sampurna",
                element : <LifeAtSSD />
            },
            {
                path : "/board",
                element : <Board />
            },
            {
                path : "/management",
                element : <Management />
            },
            {
                path : "/grievance-redressal",
                element : <GrievanceRedressal />
            },
            {
                path : "/contact",
                element : <Contact />
            },
            {
                path : "/privacy-policy",
                element : <PrivacyPolicy />
            },
            {
                path : "/cancel-policy",
                element : <CancellationPolicy />
            },
            {
                path : "/refund-policy",
                element : <RefundPolicy />
            },
            {
                path : "*",
                element : <NotFound />
            }
        ]
    }
])

export default router;