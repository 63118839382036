import React from "react";
import "./HomeHero.css";
import Typewriter from 'typewriter-effect';

const HomeComp = () => {

  return (
    <>
      <section className="home_hero">
        <div className="container">
          <div className="home_hero_content animate-slideup">
            <h1 className="font-semibold text-sm sm:text-2xl w-full md:w-[500px] md:text-4xl">Sampurna Skills Development PVT LTD</h1>
            <h3 className="font-semibold text-[.8rem] sm:text-base md:text-2xl">Skill up with</h3>
            <span className="type text-base md:text-xl">
              <Typewriter
                options={{
                  strings: ["Best Fashion Courses", "Best Makeup & Beauty Courses", "Best Computer & Peripherals Courses"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeComp;
