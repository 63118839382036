import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p>The requested page does not exist.</p>
      <p>
        Please return to the{" "}
        <Link
          style={{
            color: "var(--magenda)",
            textTransform: "uppercase",
            fontWeight: "600",
          }}
          to="/"
        >
          home page
        </Link>
        .
      </p>
    </div>
  );
}
